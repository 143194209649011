import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { pathname } from "./util/PathName";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import AppRouter from "./routes";
import "./App.css";

function App() {
	const location = useLocation();
	const paths = location.pathname.split("/");
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem("userToken") && location.pathname === "/") {
			navigate("/dashboard", { replace: true });
		}
		localStorage.setItem("api_url", process.env.REACT_APP_API_LOCAL_URL)
	}, [location.pathname, navigate]);

	return (
		<>
			<Helmet>
				<title>
					{paths[1].length > 0
						? pathname(paths[paths.length - 1])
							.charAt(0)
							.toUpperCase() + pathname(paths[paths.length - 1]).slice(1)
						: "Login"}
				</title>
			</Helmet>

			<AppRouter />

			<ToastContainer
				theme='light'
				autoClose={3000}
				closeButton={true}
				closeOnClick={false}
				draggable={false}
			/>
		</>
	);
}

export default App;
