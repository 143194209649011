import useForms from "../../../context/forms/useForms";
import { tableColumnParser } from "../../../util/tableColumnParser";
import useApi from "hooks/useApi";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import TableAction from "Components/TableAction";
import ViewForm from "Components/ViewForm";
import { SwitchButton } from "Components";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import MyDocument from "./pdfDocument";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoIosCheckmarkCircle, IoIosCloseCircle } from "react-icons/io";


const API_NAME = "workorder";

export default function useTable({ page, action }) {
	const { GET, PUT } = useApi();
	const queryClients = useQueryClient();
	const { getForm, loading } = useForms();
	const form = getForm(API_NAME);
	const { accessPath } = useUser(form?.formName);

	const filterOutData = ["signatureLine", "additionalGSTDetails", "additionalContactNo", "additionalLocationNameOfContactPerson", "additionalEmailId"]

	const response = useQuery({
		queryKey: [
			API_NAME,
			page.page,
			page.size,
			page.column,
			page.keyword,
			page.mongoQuery,
		],
		queryFn: async () =>
			await GET(
				`/${API_NAME}?page=${page.page}&size=${page.size}&columnName=${page.column}&keyword=${page.keyword}&mongoQuery=${page.mongoQuery}`,
			),
		enabled: accessPath.view,
	});

	const status = useMutation({
		mutationFn: async (body) => await PUT("workorder", body),
	});

	const parseColumn = form ? tableColumnParser(form.formComponents) : []; //gets the columns from the form for table with component key mapping to accessor and label to header ;

	console.log("parseColumn=======>", parseColumn)

	const filter = parseColumn
		.map((item) => ({
			label: item.Header,
			key: item.accessor,
		}))
		.concat({
			label: "User Added Date",
			key: "createdAt",
		});

	// const statusHandler = (body) => {
	// 	status.mutate(body, {
	// 		onSuccess: async (data) => {
	// 			await queryClients.invalidateQueries(["user"]);
	// 			toast.success("User status changed");
	// 		},
	// 		onError: () => {
	// 			toast.error("Failed to change status");
	// 		},
	// 	});
	// };

	const handleStatus = (id, approvedStatus) => {
		const body = {
			_id: id,
			approved: approvedStatus
		}
		status.mutate(body, {
			onSuccess: async (data) => {
				await queryClients.invalidateQueries(["user"]);
				toast.success("Workorder approved successfully");
			},
			onError: () => {
				toast.error("Failed to change status");
			},
		});
	}
	const additionalColumn = [
		// {
		// 	Header: "Status",
		// 	accessor: "status",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return (
		// 			value.approved===true?"Approved":"Rejected"
		// 		);
		// 	},
		// },
		// {
		// 	Header: "Approved/Rejected By",
		// 	accessor: "approved/rejectedby",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return (
		// 			(value.approved===true || value.approved===false)?value.approvedBy:""
		// 		);
		// 	},
		// },
		{
			Header: "View",
			accessor: "view",
			Cell: ({ row }) => {
				const value = row.original;
				return <ViewForm data={value} formName={API_NAME} />;
			},
		},
		// {
		// 	Header: "Action",
		// 	accessor: "action",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;

		// 		return (<>
		// 			<TableAction
		// 				path={API_NAME}
		// 				value={value}
		// 				editAction={action.onEdit}
		// 				accesspoint='workorder'
		// 				showPrintButton={false}
		// 			// PdfTemplate={() => <MyDocument data={value} />}
		// 			/>
		// 			{(value.approved !== true && value.approved !== false) && <div className='d-flex'>
		// 				<OverlayTrigger placement='top' overlay={<Tooltip>Reject</Tooltip>}>
		// 					<Button
		// 						size='sm'
		// 						className='bg-transparent border-0'
		// 						onClick={() => handleStatus(value._id, false)}>
		// 						<IoIosCloseCircle className='text-danger' size='1.7em' />
		// 					</Button>
		// 				</OverlayTrigger>
		// 				<OverlayTrigger placement='top' overlay={<Tooltip>Accept</Tooltip>}>
		// 					<Button
		// 						size='sm'
		// 						className='  border-0 bg-transparent'
		// 						onClick={() => handleStatus(value._id, true)}>
		// 						<IoIosCheckmarkCircle size='1.7em' className='text-success' />{" "}
		// 					</Button>
		// 				</OverlayTrigger>
		// 			</div>}
		// 		</>
		// 		);
		// 	},
		// },
	];

	const columns = [
		// {
		// 	Header: "Employee ID",
		// 	accessor: "name",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.employeeId;
		// 	},
		// },
		...parseColumn.filter(item => !filterOutData.includes(item.accessor)),
		// {
		// 	Header: "Customer Name",
		// 	accessor: "customerName",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.customerDetails?.name;
		// 	},
		// },
		{
			Header: "Customer Location",
			accessor: "customerLocation",
			Cell: ({ row }) => {
				const value = row.original;
				return value.customerDetails?.address;
			},
		},
		// {
		// 	Header: "Description of Products/Services Ordered",
		// 	accessor: "descriptionOfProductsServices",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.descriptionOfProductsServices;
		// 	},
		// },
		// {
		// 	Header: "Quantity Ordered",
		// 	accessor: "quantity",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.quantity;
		// 	},
		// },
		// {
		// 	Header: "Unit Price",
		// 	accessor: "unitPrice",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.unitPrice;
		// 	},
		// },
		{
			Header: "Total Price",
			accessor: "totalPrice",
			Cell: ({ row }) => {
				const value = row.original;
				const totalUnitPrice = value.quantity * value.unitPrice;
				const discount = (100 - value.discount) / 100;
				return (totalUnitPrice * discount).toFixed(2);
			},
		},
		// {
		// 	Header: "Discounts Applied",
		// 	accessor: "discount",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.discount;
		// 	},
		// },
		// {
		// 	Header: "Payment Method",
		// 	accessor: "paymentMethod",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.paymentMethod;
		// 	},
		// },
		// {
		// 	Header: "Payment Schedule",
		// 	accessor: "paymentSchedule",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.quotationsDetails.paymentSchedule;
		// 	},
		// },
	]
		.concat(additionalColumn);
	const rows = response.data ? response.data.response.data : [];

	const pagination = response.data
		? response.data.response
		: { page: 0, showing: 0, total: 0 };
	return {
		columns,
		rows,
		loading: loading || response.isLoading,
		filter,
		pagination,
	};
}
