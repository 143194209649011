import { lazy } from "react";

export const ApprovalList = lazy(() => import("./ApprovalList"));
export const QuotationApproval = lazy(() => import("./QuotationApproval"));

export const Customer = lazy(() => import("./Customer"));
export const Enquiry = lazy(() => import("./Enquiry"));
export const Department = lazy(()=> import("./Department"));
export const Employee = lazy(()=> import("./Employee"));
export const Designation = lazy(()=> import("./Designation"));

export const CustomerApproval = lazy(() => import("./CustomerApproval"));


export const ChemistDataEntry = lazy(() => import("./ChemistDataEntry"));


export const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
export const DispatchList = lazy(() => import("./DispatchList"));

export const FormsBuilder = lazy(() => import("./FormBuilder/FormsBuilder"));
export const FormsList = lazy(() => import("./FormList/FormsList"));
export const FormsListHistory = lazy(() =>
	import("./FormList/FormListHistory"),
);

export const InternalUser = lazy(() => import("./User/"));

export const JobRegestration = lazy(() => import("./JobRegestration"));

export const Mainlayout = lazy(() => import("./Mainlayout/MainLayout"));

export const ProformaInvoice = lazy(() => import("./ProformaInvoice"));

export const RoleAndPermission = lazy(() => import("./RolesAndPermission"));
export const Report = lazy(() => import("./Report"));

export const SampleAccepted = lazy(() => import("./SampleAccepted"));
export const SampleName = lazy(() => import("./SampleName"));
export const SampleParameter = lazy(() => import("./SampleParameter"));
export const SampleList = lazy(() => import("./SampleRegestration"));
export const SiteSetting = lazy(() => import("./SiteSetting/SiteSetting"));
export const SampleReceiving = lazy(() => import("./SampleReceiving"));
export const SampleRejected = lazy(() => import("./SampleRejected"));
export const SampleDispatch = lazy(() => import("./SampleDispatch"));

export const SampleReceiving1 = lazy(() => import("./LabTempMenu/SampleReceiving"));
export const SampleAllotment = lazy(() => import("./LabTempMenu/SampleAllotment"));
export const SampleRejected1 = lazy(() => import("./LabTempMenu/SampleRejected"));
export const SampleAccepted1 = lazy(() => import("./LabTempMenu/SampleAccepted"));
export const ChemistDataEntry1 = lazy(() => import("./LabTempMenu/Result"));
export const ResultApproval = lazy(() => import("./LabTempMenu/ResultApproval"));

export const ParameterMaster = lazy(() => import("./ParameterMaster"));

export const UnitOfScale = lazy(() => import("./UnitOfScale"));

export const PageNotFound = lazy(() => import("./404/404"));
