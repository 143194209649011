import useApi from "hooks/useApi";
import { Form } from "@formio/react";
import { toast } from "react-toastify";
import parsedForm from "util/formParser";
import useForms from "context/forms/useForms";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import OverlayLoading from "Components/OverlayLoading/OverlayLoading";
import { useEffect, useState } from "react";
import bodyParser from "util/bodyParser";

const API_NAME = "workorder";

export default function MainForm({ updatemode, data, closeForm }) {
	const [formData, setFormData] = useState({});
	const { getForm } = useForms();
	const { POST, PUT } = useApi();
	const queryClient = useQueryClient();
	const form = parsedForm(getForm(API_NAME));

	const mutation = useMutation({
		mutationFn: updatemode
			? async (body) => await PUT(API_NAME, body)
			: async (body) => await POST(API_NAME, body),
	});

	useEffect(() => {
		if (updatemode) {
			setFormData(data);
		}
	}, [data, updatemode]);

	const submitHandler = (data) => {
		const body = bodyParser(data.data, form.components);
		mutation.mutate(body, {
			onSuccess: async (data) => {
				await queryClient.invalidateQueries([API_NAME]);
				toast.success(data.message);
				closeForm();
			},
			onError: (error) => {
				toast.error(error.message);
			},
		});
	};

	return (
		<>
			{mutation.isPending && <OverlayLoading />}
			<Form
				src={form}
				onSubmit={submitHandler}
				submission={{ data: formData }}
				options={{
					readOnly: false,
					disabled: {
						email: updatemode,
					},
				}}
			/>
		</>
	);
}
