import useForms from "../../../context/forms/useForms";
import { tableColumnParser } from "../../../util/tableColumnParser";
import useApi from "hooks/useApi";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import TableAction from "Components/TableAction";
import ViewForm from "Components/ViewForm";
import { SwitchButton } from "Components";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import MyDocument from "./pdfDocument";

const API_NAME = "quotations";

export default function useTable({ page, action }) {
	const { GET, PUT } = useApi();
	const queryClients = useQueryClient();
	const { getForm, loading } = useForms();
	const form = getForm(API_NAME);
	const { accessPath } = useUser(form?.formName);

	const filterOutData = ["date","validityDate","estimatedDeliveryDate","signatureLine","additionalGSTDetails","additionalContactNo","additionalLocationNameOfContactPerson","additionalEmailId","itemServiceCategory","testAndParameters"]

	const response = useQuery({
		queryKey: [
			API_NAME,
			page.page,
			page.size,
			page.column,
			page.keyword,
			page.mongoQuery,
		],
		queryFn: async () =>
			await GET(
				`/${API_NAME}?page=${page.page}&size=${page.size}&columnName=${page.column}&keyword=${page.keyword}&mongoQuery=${page.mongoQuery}`,
			),
		enabled: accessPath.view,
	});

	const status = useMutation({
		mutationFn: async (body) => await PUT("quotations", body),
	});

	const parseColumn = form ? tableColumnParser(form.formComponents) : []; //gets the columns from the form for table with component key mapping to accessor and label to header ;

	console.log("parseColumn=======>",parseColumn)
	
	const filter = parseColumn
		.map((item) => ({
			label: item.Header,
			key: item.accessor,
		}))
		.concat({
			label: "User Added Date",
			key: "createdAt",
		});

	const statusHandler = (body) => {
		status.mutate(body, {
			onSuccess: async (data) => {
				await queryClients.invalidateQueries(["user"]);
				toast.success("User status changed");
			},
			onError: () => {
				toast.error("Failed to change status");
			},
		});
	};
	const additionalColumn = [
		{
			Header: "Approval Status",
			accessor: "status",
			Cell: ({ row }) => {
				const value = row.original;
				return (
					<>{value.status==="Reject"?"Request Resubmission":(value.status? value.status: "Pending")}</>
				);
			},
		},
		{
			Header: "Quotation Status",
			accessor: "usedstatus",
			Cell: ({ row }) => {
				const value = row.original;
				return (
					<SwitchButton
						loading={status.isPending || response.isFetching}
						active={!value.usedStatus}
						disabled={true}
						// onChange={(status) => {
						// 	statusHandler({
						// 		_id: value._id,
						// 		status: status ? "active" : "inactive",
						// 	});
						// }}
					/>
				);
			},
		},
		{
			Header: "View",
			accessor: "view",
			Cell: ({ row }) => {
				const value = row.original;
				return <ViewForm data={value} formName={API_NAME} />;
			},
		},
		{
			Header: "Action",
			accessor: "action",
			Cell: ({ row }) => {
				const value = row.original;

				return (
					<TableAction
						path={API_NAME}
						value={value}
						editAction={action.onEdit}
						accesspoint='customer'
						showPrintButton={false}
						// PdfTemplate={() => <MyDocument data={value} />}
					/>
				);
			},
		},
	];

	const columns = [
		// {
		// 	Header: "Employee ID",
		// 	accessor: "name",
		// 	Cell: ({ row }) => {
		// 		const value = row.original;
		// 		return value.employeeId;
		// 	},
		// },
		...parseColumn.filter(item=>!filterOutData.includes(item.accessor)),
		{
			Header: "Date",
			accessor: "date",
			Cell: ({ row }) => {
				const value = row.original;
				return value.dateFormat;
			},
		},
		{
			Header: "Estimated Delivery Date",
			accessor: "estimateddeliverydate",
			Cell: ({ row }) => {
				const value = row.original;
				return value.estimatedDeliveryDateFormat;
			},
		},
		{
			Header: "Validity Date",
			accessor: "validityDate",
			Cell: ({ row }) => {
				const value = row.original;
				return value.validityDateFormat;
			},
		},
	]
	.concat(additionalColumn);
	const rows = response.data ? response.data.response.data : [];

	const pagination = response.data
		? response.data.response
		: { page: 0, showing: 0, total: 0 };
	return {
		columns,
		rows,
		loading: loading || response.isLoading,
		filter,
		pagination,
	};
}
