import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
} from "@react-pdf/renderer";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { MdPrint } from "react-icons/md";

const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		border: "1px solid black",
		padding: 20,
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	header: {
		backgroundColor: "lightblue",
		height: "10mm",
		textAlign: "center",
	},
	formHeader: {
		fontSize: 20,
		marginTop: 5,
		marginBottom: 5,
		paddingBottom: 5,
		borderBottom: "1px solid black",
		borderTop: "1px solid black",
	},
	form: {
		flexDirection: "row",
	},
	formItem: {
		width: 200,
		marginTop: 5,
		marginBottom: 5,
		fontSize: 10,
	},
});

const MyDocument = ({ data }) => {
	const [pdf, setPdf] = useState(false);
	const value = data;

	const PDF = () => (
		<Document>
			<Page size='A4' style={styles.page}>
				<View style={styles.header}>
					<Text style={{ textAlign: "center" }}>
						Trueworths Minerals Solution Pvt. ltd.
					</Text>
				</View>
				<View>
					<View>
						<Text style={styles.formHeader}>Customer Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Name : {value.name}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Customer Id : {value.customerId}</Text>
						</View>
					</View>
					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Email : {value.email}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Contact Number : {value.phoneNumber}</Text>
						</View>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Address : {value.address}</Text>
						</View>
					</View>

					<View>
						<Text style={styles.formHeader}>Business Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>PAN : {value.pan}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>GST : {value.gstIn}</Text>
						</View>
					</View>

					<View>
						<Text style={styles.formHeader}>Credit Details</Text>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Credit Limit : {value.creditLimitAllowed}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Credit Period : {value.creditPeriodAllowed}</Text>
						</View>
						<View style={styles.formItem}>
							<Text>Previous Pending Amount : {value.overDue}</Text>
						</View>
					</View>

					<View style={styles.form}>
						<View style={styles.formItem}>
							<Text>Remarks : </Text>
							<Text style={{ marginTop: 5 }}>{value.remarks}</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);

	return (
		<>
			<Modal show={pdf} size='xl' onHide={() => setPdf(false)}>
				<Modal.Header className='bg-transparent' closeButton></Modal.Header>
				<Modal.Body>
					<PDFViewer width={"100%"} height={"800px"}>
						<PDF />
					</PDFViewer>
				</Modal.Body>
			</Modal>

			<Button
				size='sm'
				onClick={() => setPdf(true)}
				className='  border-0 bg-transparent'>
				<MdPrint size='1.7em' className='text-primary' />
			</Button>
		</>
	);
};

export default MyDocument;
